import  React, {useState, useEffect, useContext} from 'react';
import ListItem from './ListItem';
import '../css/ListView.css';
import AppDataContext from "./context/AppDataContext";
import GridItem from "./GridItem";
import {appUtil} from "../constant/appUtil";
import LoadingOverlay from "./LoadingOverlay";


function ListView() {

    const {appData, setAppData} = useContext(AppDataContext);
    const [listItems,setListItems] = useState(null);

    useEffect(()=> {
        if(appData.loaded)
        {
            let rows = [];
            appData.filteredProperties.forEach(function(property){
                rows.push(<ListItem key={property.id} property={property} />);
            })
            setListItems(rows)

        }
    },[appData.loaded,appData.filteredProperties])


    return (
        <section className={listItems ? "primary list-view " :"primary list-view loading"}>
            <LoadingOverlay loaded={!!listItems}/>
            <div className="inner">
                <table className="table table-hover">
                    <thead className="thead-dark">
                    <tr>
                        <th>STATUS</th>
                        <th>NUMBER</th>
                        <th>UNIT AREA</th>
                        <th>GARDEN AREA</th>
                        <th>TERRACE AREA</th>
                        <th>BATHROOMS</th>
                        <th>PARKING</th>

                    </tr>
                    </thead>

                    <tbody >
                    {listItems}

                    </tbody>
                </table>
            </div>
        </section>
    )

}

export default ListView;