export const propertyConst = {
    slider : {
        "11" : [
            {source : require("../img/property_slider/11/i1.jpg")},
            {source : require("../img/property_slider/11/i2.jpg")},
            {source : require("../img/property_slider/11/i3.jpg")},
            {source : require("../img/property_slider/11/i4.jpg")},
        ],
        "21" : [
            {source : require("../img/property_slider/21/i1.jpg")},
            {source : require("../img/property_slider/21/i2.jpg")},
            {source : require("../img/property_slider/21/i3.jpg")},
            {source : require("../img/property_slider/21/i4.jpg")},
            {source : require("../img/property_slider/21/i5.jpg")},
            {source : require("../img/property_slider/21/i6.jpg")},
        ],
        "31" : [
            {source : require("../img/property_slider/31/i1.jpg")},
            {source : require("../img/property_slider/31/i2.jpg")},
            {source : require("../img/property_slider/31/i3.jpg")},
            {source : require("../img/property_slider/31/i4.jpg")},
            {source : require("../img/property_slider/31/i5.jpg")},
            {source : require("../img/property_slider/31/i6.jpg")},
            {source : require("../img/property_slider/31/i7.jpg")},
            {source : require("../img/property_slider/31/i8.jpg")},
            {source : require("../img/property_slider/31/i9.jpg")},
        ],
        "31-ph" : [
            {source : require("../img/property_slider/31-ph/i1.jpg")},
            {source : require("../img/property_slider/31-ph/i2.jpg")},
            {source : require("../img/property_slider/31-ph/i3.jpg")},
            {source : require("../img/property_slider/31-ph/i4.jpg")},
            {source : require("../img/property_slider/31-ph/i5.jpg")},
            {source : require("../img/property_slider/31-ph/i6.jpg")},
            {source : require("../img/property_slider/31-ph/i7.jpg")},
            {source : require("../img/property_slider/31-ph/i8.jpg")},
            {source : require("../img/property_slider/31-ph/i9.jpg")},
        ],
        "42-lux" : [
            {source : require("../img/property_slider/42-lux/i1.jpg")},
            {source : require("../img/property_slider/42-lux/i2.jpg")},
            {source : require("../img/property_slider/42-lux/i3.jpg")},
            {source : require("../img/property_slider/42-lux/i4.jpg")},
            {source : require("../img/property_slider/42-lux/i5.jpg")},
            {source : require("../img/property_slider/42-lux/i6.jpg")},
            {source : require("../img/property_slider/42-lux/i7.jpg")},
            {source : require("../img/property_slider/42-lux/i8.jpg")},
            {source : require("../img/property_slider/42-lux/i9.jpg")},
        ],


    },
    thumb : {
        "11" : [
            {source : require("../img/property_thumbs/11/i1.jpg")},
        ],
        "21" : [
            {source : require("../img/property_thumbs/21/i1.jpg")},
        ],
        "31" : [
            {source : require("../img/property_thumbs/31/i1.jpg")},
        ],
        "31-ph" : [
            {source : require("../img/property_thumbs/31-ph/i1.jpg")},
        ],
        "42-lux" : [
            {source : require("../img/property_thumbs/42-lux/i1.jpg")},
        ],


    },
    vr360 : {

    }
}