import React, {useState, useEffect, useContext} from 'react';
import {
  Link, withRouter,useHistory
} from "react-router-dom";
import Filter from './Filter.js';
import UserContext from "./context/UserContext.js";
import '../css/MainMenu.css';
import SubMenu from "./SubMenu";
import '../constant/appConst';
import {appConst, appSettings} from "../constant/appConst";
import AppDataContext from "./context/AppDataContext";
import ClientMenu from "./ClientMenu";
import ClientContext from "./context/ClientContext";
import UserMenu from "./UserMenu";
import Favorites from "./Favorites";

function MainMenu(props) {

  const [isOpen, setIsOpen] = useState(false);
  const [showMenuWrap, setShowMenuWrap] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [showGallerySubMenu, setShowGallerySubMenu] = useState(false);
  const [showLocationSubMenu, setShowLocationSubMenu] = useState(false);

  const [showFavorites, setShowFavorites] = useState(false);
  const { user, setUser } = useContext(UserContext);
  const { client, setClient } = useContext(ClientContext);
  const { appData, setAppData } = useContext(AppDataContext);
  const history = useHistory();

  useEffect(() => {

    if (isOpen){
      document.body.classList.add('main-menu-is-open');
    }
    else {
      document.body.classList.remove('main-menu-is-open');
    }

  }, [isOpen]);

  useEffect(() => {

    if (user) showMainMenu()
    
  }, [user]);

  useEffect(() => {

    if (client) showMainMenu()

  }, [client]);


  function showMainMenu(){
    setShowMenuWrap(true);
    setShowFilter(false);
    setShowGallerySubMenu(false);
    setShowFavorites(false);
  }
  function onClickFilter(){
    if(appSettings.showRegisterClientPage) {
      if(user)
      {
        setShowMenuWrap(false);
        setShowFilter(true);
      }
      else
        history.push("/register")
    }
    else
    {
      setShowMenuWrap(false);
      setShowFilter(true);
    }


  }


  function onClickLogin(){

    history.push("/salesLogin")
    // setShowMenuWrap(false);
    // setShowLoginBox(true);

  }

  function onClickInviteClient(){
    if(!isOpen) setIsOpen(true);
    setShowFilter(false);
    setShowGallerySubMenu(false);
    setShowMenuWrap(false);
    setShowFavorites(false);

  }

  function onClickGallery(){
    if(appSettings.showRegisterClientPage) {
      if (user) {
        setShowMenuWrap(false);
        setShowGallerySubMenu(true);
      } else
        history.push("/register")
    }
    else {
      setShowMenuWrap(false);
      setShowGallerySubMenu(true);
    }


  }

  function onClickLocation(){
    if(appSettings.showRegisterClientPage) {
      if(user)
      {
        setShowMenuWrap(false);
        setShowLocationSubMenu(true);
      }
      else
        history.push("/register")
    }
    else {
      setShowMenuWrap(false);
      setShowLocationSubMenu(true);
    }



  }

  function onClickFavorites(){

    if(!isOpen) setIsOpen(true);
    setShowFilter(false);
    setShowGallerySubMenu(false);
    setShowMenuWrap(false);
    setShowFavorites(true);


  }

  function onClickFilterBack(){

    setShowMenuWrap(true);
    setShowFilter(false);

    let newAppData = {...appData};
    newAppData.isFilterModeActive = false;
    setAppData(newAppData);

  }



  function onClickGalleryBack(){

    setShowMenuWrap(true);
    setShowGallerySubMenu(false);

  }

  function onClickLocationBack(){

    setShowMenuWrap(true);
    setShowLocationSubMenu(false);

  }


  function onClickFavoritesBack(){

    setShowMenuWrap(true);
    setShowFavorites(false);

  }





  return(

    
       <div>
          
        <section className="main-menu">
        
            <button
              className="main-menu-icon"
              onClick={()=> {

                setIsOpen(!isOpen);
                
              }}
            > 
              <div></div>
              <div></div>
            </button>
            
          {showMenuWrap && (

              <div className="menu-wrap">
                
                  <Link to="/" className="item home">
                      <div className="inner"></div>
                  </Link>

                  <Link to={appSettings.showRegisterClientPage ? (user ? "/location/0" : "/register") : "/location/0"} className="item location"  onClick={onClickLocation}>
                      <div className="inner">LOCATION</div>
                  </Link>

                  <div className="item gallery" onClick={onClickGallery}>
                      <div className="inner">GALLERY</div>
                  </div>

                  <Link to={appSettings.showRegisterClientPage ? (user ? "/#." : "/register") : "/#."} className="item brochure">
                      <div className="inner">BROCHURE</div>
                  </Link>
                
                <div className="item filter" onClick={onClickFilter} >
                  <div className="inner">FILTER</div>
                </div>


                <div className="menu-footer">
                  
                  {user && <UserMenu />}

                  {!user &&
                     <button className="default-button login" onClick={onClickLogin}>SALES LOGIN</button>
                  }
                  
                 
                </div>

              </div>

            )}
            
            {showFilter &&
                
                <Filter onClickButtonBack = {onClickFilterBack} />
                
            }





          {(showFavorites && client) &&

          <Favorites onClickButtonBack = {onClickFavoritesBack} />

          }


          {showGallerySubMenu &&
              <SubMenu submenu={appConst.menu.gallery} onClickButtonBack={onClickGalleryBack}/>
          }

          {showLocationSubMenu &&
          <SubMenu submenu={appConst.menu.location} onClickButtonBack={onClickLocationBack}/>
          }

          {
            (() => {
              if (user)
                if(!props.location.pathname.includes("dashboard"))
                  return <ClientMenu onClickInviteClient={onClickInviteClient} onClickFavorites={onClickFavorites} showMainMenu={showMainMenu} />

            })()
          }



          

        </section>
            
        
      
      </div>
    

  );

}

export default withRouter(MainMenu);