import MapMarker from "../components/MapMarker";
import GoogleMap from "google-map-react";
import React from "react";

const appSettings = {
    projectName: "Terra",
    currencyUnit: "£",
    currencyUnitPosition: "before",
    areaUnit: <>m<sup>2</sup></>,
    showPrices: true,
    priceFilter: {
        unit: 10000,
        min: 0,
        max: 1100000,
    },
    showIntroVideo : true,
    clientVerification : false,
    showRegisterClientPage : true,
    botSalesRep : "developer@wizio.com.tr"
};

const appConst = {
    
    menu: {
        gallery : [
            {id:1, title:'Exterior', link: '/gallery/exterior'},
            {id:2, title:'Interior', link: '/gallery/interior'},
            {id:6, title:'Video', link: '/gallery/video'},

        ],
        location : [
            {id:1, title:'The English School of Kyrenia', link: '/location/1', lat:35.316536168252995,  lng:33.3433540029714,},
            {id:2, title:'Necat British College', link: '/location/2', lat:35.33794866937777,  lng:33.20846462634382,},
            {id:3, title:'Merit Park Hotel', link: '/location/3', lat:35.34914665895974,  lng:33.25692197033918,},
            {id:4, title:'Merit Crystal Cove Hotel', link: '/location/4', lat:35.355496059167926,  lng:33.21335192086748,},
            {id:5, title:'Merit Royal Hotel', link: '/location/5', lat:35.35498922917264,  lng:33.21015969520524,},
            {id:6, title:'Merit Royal Premium Hotel', link: '/location/6', lat:35.35516800168269,  lng:33.20899429687824,},
            {id:7, title:'Denizkızı Hotel', link: '/location/7', lat:35.35004836335275,  lng:33.2259285585723,},
            {id:8, title:'Escape Beach Club', link: '/location/8', lat:35.34714476930071,  lng:33.23431643065778,},
            {id:9, title:'İleli Market', link: '/location/9', lat:35.34845691914162,  lng:33.22226033088863,},
            {id:10, title:'Starling Market', link: '/location/10', lat:35.348605158644474,  lng:33.221364199404306,},
            {id:11, title:'Ercan Airport', link: '/location/11', lat:35.15784940841558,  lng:33.50266494155056,},
            {id:12, title:'Alsancak National Nature Park', link: '/location/12', lat:35.34415155261099,  lng:33.2383552358189,},
            {id:13, title:'Korineum Golf and Beach Resort', link: '/location/13', lat:35.32573272725261,  lng:33.51652427557437,},
            {id:14, title:'Cyprus Dive Shack', link: '/location/14', lat:35.34727031625308,  lng:33.234054472207646,},
            {id:15, title:'St Hilarion Castle', link: '/location/15', lat:35.311436122157176,  lng:33.2811014333433,},
            {id:16, title:'Kyrenia Harbour', link: '/location/16', lat:35.34168079100448,  lng:33.32033711802208,},
            {id:17, title:'Akçiçek Hospital', link: '/location/17', lat:35.33773659777154,  lng:33.325553694583206,},
            {id:18, title:'Lapta Sahil Yürüyüş Yolu', link: '/location/18', lat:35.351823,  lng:33.167044,},
            {id:19, title:'Bellapais Monastery', link: '/location/19', lat:35.30703668564499,  lng:33.35524477632913,},
            {id:20, title:'Girne American University', link: '/location/20', lat:35.333332130006326,  lng:33.27524708198668,},
            {id:21, title:'Deniz Kızı Beach', link: '/location/21', lat:35.35004836335275,  lng:33.2259285585723,},
            {id:22, title:'Camelot Beach', link: '/location/22', lat:35.3560334256465,  lng:33.193324152774785,},
            {id:23, title:'Kervansaray Beach', link: '/location/23', lat:35.34791591197422,  lng:33.25563387072051,},
            {id:24, title:'Gave', link: '/location/24', lat:35.34368621609102,  lng:33.23045631162437,},
            {id:25, title:'Kybele', link: '/location/25', lat:35.30741857885442,  lng:33.355046169438445,},
            {id:26, title:'Archway Restaurant', link: '/location/26', lat:35.33039728611647,  lng:33.29621856845882,},
            {id:27, title:'Carpenter’s', link: '/location/27', lat:35.344832682986066,  lng:33.262991270310266,},
            {id:28, title:'Silver Rocks', link: '/location/28', lat:35.35201508142805,  lng:33.16425916845932,},
            {id:29, title:'Calamari', link: '/location/29', lat:35.35072026179594,  lng:33.17597641508618,},
            {id:30, title:'Eziç Premier', link: '/location/30', lat:35.32669088788368,  lng:33.30845851993099,},
            {id:31, title:'Gilham Vineyard', link: '/location/31', lat:35.32384603745733,  lng:33.22110912915224,},
            {id:32, title:'Kervan Cafe', link: '/location/32', lat:35.34774209276883,  lng:33.25617832052391,},
            {id:33, title:'Peri’s Fish Restaurant', link: '/location/33', lat:35.34715908639137,  lng:33.25622455066817,},
        ]

    },
    gallery : {

        exterior : [
            {caption:'', source: require("../img/gallery/exterior/i1.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i2.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i3.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i4.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i5.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i6.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i7.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i8.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i9.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i10.jpg")},
            {caption:'', source: require("../img/gallery/exterior/i11.jpg")},

        ],
        interior : [
            {caption:'', source: require("../img/gallery/interior/i1.jpg")},
            {caption:'', source: require("../img/gallery/interior/i2.jpg")},
            {caption:'', source: require("../img/gallery/interior/i3.jpg")},
            {caption:'', source: require("../img/gallery/interior/i4.jpg")},
            {caption:'', source: require("../img/gallery/interior/i5.jpg")},
            {caption:'', source: require("../img/gallery/interior/i6.jpg")},
            {caption:'', source: require("../img/gallery/interior/i7.jpg")},
            {caption:'', source: require("../img/gallery/interior/i8.jpg")},
            {caption:'', source: require("../img/gallery/interior/i9.jpg")},
            {caption:'', source: require("../img/gallery/interior/i10.jpg")},
            {caption:'', source: require("../img/gallery/interior/i11.jpg")},

        ],

    },
    brochure : [

    ],

    status : {
        available : 1,
        reserved : 2,
        sold : 3,
        not_released : 4
    },
    getStatusTitle : function(id){
        let str = "";
        if(id === 1)  str = "Available";
        if(id === 2)  str = "Reserved";
        if(id === 3)  str = "Sold";
        if(id === 4)  str = "Not Released";

        return str;
    },
    role : {
        sales_rep : 1,
        sales_manager : 2,
        general_manager : 3,
        broker : 4
    },
    showModal : function(className){
        document.querySelector("body").classList.add("modal-open");
        document.querySelector(".modal."+className).classList.add("show");
        document.querySelector(".modal-backdrop").classList.add("show");
    },
    closeModal : function(className){
        document.querySelector("body").classList.remove("modal-open");
        document.querySelector(".modal."+className).classList.remove("show");
        document.querySelector(".modal-backdrop").classList.remove("show");
    }

};

export {
    appSettings,
    appConst,
};