import React, {useContext, useEffect, useState} from 'react';
import "../css/PropertyView.css"
import AwesomeSlider from "react-awesome-slider";
import FavButton from "./FavButton";
import {withRouter,useParams} from "react-router-dom";
import {apiConst} from "../constant/apiConst";
import {appUtil} from "../constant/appUtil";
import AlertContext from "./context/AlertContext";
import {propertyConst} from "../constant/propertyConst";
import UserContext from "./context/UserContext";
import ClientContext from "./context/ClientContext";
import Confirm from "./Confirm";
import {appConst} from "../constant/appConst";
import FavContext from "./context/FavContext";
import ConfirmContext from "./context/ConfirmContext";
import AppDataContext from "./context/AppDataContext";
import LightBox from "./LightBox";
import LightBoxContext from "./context/LightBoxContext";
import Viewer360 from "./Viewer360";
import {appSettings} from "../constant/appConst";

function PropertyView(props) {

    const {appData, setAppData} = useContext(AppDataContext);
    const {user,setUser} = useContext(UserContext);
    const {client,setClient} = useContext(ClientContext);
    const {alert,setAlert} = useContext(AlertContext);
    const {confirm,setConfirm} = useContext(ConfirmContext);
    const {lightBox,setLightBox} = useContext(LightBoxContext);
    const {favs,setFavs} = useContext(FavContext);

    const [is360Active,set360Active] = useState(false);
    const [property,setProperty] = useState(null);
    const [slider,setSlider] = useState(null);
    const [favId,setFavId] = useState(0);
    const [isSoldOrOption,setIsSoldOrOption] = useState("");
    const [isFavWaiting,setIsFavWaiting] = useState(false);

    const [clickParam,setClickParam] = useState(-1);
    const params = useParams()

    useEffect(() => {
        apiConst.getProperty(params.property_id).then(result => {
            setProperty(result);
            if(result.status_id === appConst.status.sold)
                setIsSoldOrOption("Sold");
            else if(result.status_id === appConst.status.reserved)
                setIsSoldOrOption("Reserved");
            else if(result.status_id === appConst.status.not_released)
                setIsSoldOrOption("Deposit");
            else
                setIsSoldOrOption("");

        });

        if(user && client)
        {
            apiConst.findPropertyView(user.id,client.id,params.property_id).then(result => {

                if(result.response)
                {
                    let propertyView = result.propertyView;
                    let view_count = parseInt(propertyView.view_count);
                    apiConst.propertyViewUpdate(propertyView.id,++view_count).then(result => {

                    })
                }
                else
                {
                    apiConst.propertyViewCreate(user.id,client.id,params.property_id).then(result => {

                    });
                }
            })
        }

    },[params]);

    useEffect(()=> {

        if(property) {
            let roomSlug = appUtil.convertToSlug(property.room_type.label);
            let flatLevel = "flat_" +property.flatLevel;
            let buildingClass = property.building.class_name.toLowerCase();
            let sliderImages = propertyConst.slider[roomSlug];



            setSlider(sliderImages);

        }
    },[property])

    useEffect(() => {
        if(favs && property)
        {

            if(favs.length)
            {
                let id = 0
                favs.forEach((fav) => {
                    if(property.id === fav.property.id)
                        id = fav.id
                })
                setFavId(id);
            }
            else
            {
                setFavId(0);
            }

        }
    },[favs,property,favId])


    function floorPlanClick(e){
        setLightBox(true);
    }



    function view360Click(e){

        if(!is360Active)
        {
            e.target.innerHTML = "IMAGES";
            set360Active(true);
        }
        else
        {
            e.target.innerHTML = "360 TOUR";
            set360Active(false);
        }
    }



    function checkLogin()
    {
        if(!user || !client)
        {
            setAlert("There is no active user or client");
            return false;
        }

        return true
    }

    function checkBroker(){
        if(user && client) {
            if(user.role_id === appConst.role.broker){
                setAlert("You can't do any property action due to broker role")
                return false;
            }
        }
        return true
    }
    function onFavClick(){

        if(!checkLogin()) return;

        setIsFavWaiting(true);
        if(favId)
        {
            apiConst.favDelete(favId).then(result => {
                if(result.response)
                {
                    setFavId(0)
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    if(newFavs.length)
                    {
                        newFavs.forEach((fav,i) => {
                            if(fav.id === favId) newFavs.splice(i,1);
                        });
                        setFavs(newFavs);
                        sessionStorage.setItem("favs",JSON.stringify(newFavs));

                    }
                }
            })
        }
        else
        {
            apiConst.favCreate(client.id,property.id).then(result => {
                if(result.response)
                {
                    setFavId(result.propertyFav.id);
                    setIsFavWaiting(false);

                    let newFavs = [...favs];
                    newFavs.push(result.propertyFav);
                    setFavs(newFavs);
                    sessionStorage.setItem("favs",JSON.stringify(newFavs));

                }
            })
        }
    }

    function getFields(status)
    {
        let fields = {
            user_id : user.id,
            client_id : client.id,
            presentation_id : sessionStorage.getItem("presentation_id"),
            property_id : property.id,
            status_id : status
        }
        return fields;
    }

    function onBuyClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to buy this property",onConfirm:() => { onConfirm(appConst.status.sold)} })

    }

    function onOptionClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to reserve this property",onConfirm:() => { onConfirm(appConst.status.reserved)} })
    }

    function onCancelClick(){
        if(!checkLogin()) return;
        if(!checkBroker()) return;
        setConfirm({show:true,text:"You want to cancel buy or reserve process",onConfirm:() => { onConfirm(appConst.status.available)} })
    }

    function onConfirm(status){

        let fields = getFields(status)

        apiConst.propertyUpdate(property.id,fields).then(result => {

            if(result.response)
            {
                let newAppData = {...appData}
                newAppData.properties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status_id = status;
                        if(status === appConst.status.available)
                            currentProperty.status.label = "Available";
                        if(status === appConst.status.sold)
                            currentProperty.status.label = "Sold";
                        if(status === appConst.status.reserved)
                            currentProperty.status.label = "Reserved";
                        if(status === appConst.status.not_released)
                            currentProperty.status.label = "Deposit";
                    }
                });
                newAppData.filteredProperties.forEach(function(currentProperty,i){
                    if(property.id === currentProperty.id)
                    {
                        currentProperty.status_id = status;
                        if(status === appConst.status.available)
                            currentProperty.status.label = "Available";
                        if(status === appConst.status.sold)
                            currentProperty.status.label = "Sold";
                        if(status === appConst.status.reserved)
                            currentProperty.status.label = "Reserved";
                        if(status === appConst.status.not_released)
                            currentProperty.status.label = "Deposit";
                    }
                });

                newAppData.floorStatuses = newAppData.getFreshFloorStatuses();
                newAppData.buildingStatuses = newAppData.getFreshBuildingStatuses();
                window.updateBuildingStatuses(newAppData.buildingStatuses);
                setAppData(newAppData);

                let message;
                switch (status)
                {
                    case appConst.status.available:
                        message = "Successfully canceled";
                        break;
                    case appConst.status.sold:
                        message = "Successfully bought";
                        break;
                    case appConst.status.reserved:
                        message = "Successfully reserved";
                        break;
                }

                setTimeout(()=> {
                    if(status === appConst.status.available)
                    {
                        apiConst.propertyActionDelete(fields.property_id).then(result => {
                            if (result.response) {
                                console.log("propertyActions silindi")
                            }
                        })
                    }
                    else {
                        apiConst.propertyActionCreate(fields).then(result => {

                            if (result.response) {
                                console.log("propertyActions oluştu")
                            }
                        })
                    }
                },1000)

                setAlert(message);
                setIsSoldOrOption(status);





            }
        })



    }

  return (

    <section className = "primary property-view">

            <button className="close-button colored" onClick={() => props.history.goBack()}>
                <div></div>
                <div></div>
            </button>
        {lightBox && <LightBox media={require("../img/property_floorplan/" + property.building.class_name + ".jpg")}/>}
        {property && <div className="main-container">

            <div className="row h-100 no-gutters">
                <div className="col-8" onContextMenu={(e)=> e.preventDefault()}>
                    {isSoldOrOption && <div className="status-label">{isSoldOrOption}</div>}
                    {!is360Active && <AwesomeSlider className="aws-btn" bullets={false} fillParent={true} infinite={true}
                                                          media={slider}
                    />}
                    {is360Active && <Viewer360 link={propertyConst.vr360[appUtil.convertToSlug(property.room_type.label)]} />}




                </div>
                <div className="col-4">
                    <div className="property-details-container">
                        <FavButton isFav={favId} isWaiting={isFavWaiting} onClick={onFavClick}  />
                        <div className="property-details">
                            <h1 className="property-title">{property.building.code.split("-")[0] + " / "+property.number} <br/> <span>{property.room_type.label}</span></h1>
                            {appSettings.showPrices && <span className="property-price">{appUtil.formatPrice(property.price)}</span>}
                            <div className="action-buttons-container">
                                { !isSoldOrOption &&
                                    <div>
                                        <button className="default-button lined" onClick={onBuyClick}>BUY</button>
                                        <button className="default-button lined" onClick={onOptionClick}>RESERVE</button>
                                    </div>
                                }
                                { isSoldOrOption &&
                                <div>
                                    <button className="default-button lined" onClick={onCancelClick}>CANCEL</button>

                                </div>
                                }

                            </div>
                            <div className="property-info">

                            </div>
                        </div>
                        <div className="bottom-buttons">
                            <a className={property.status_id === appConst.status.sold ? "floor-plan-button d-none" : "floor-plan-button"}  onClick={floorPlanClick}>FLOOR PLAN</a>
                            <a className="tour-360-button d-none"  onClick={view360Click}>360 TOUR</a>
                        </div>

                    </div>


                </div>
            </div>

        </div>}



    </section>

  );
}

export default withRouter(PropertyView);