import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {appConst} from "../constant/appConst";
import ReactPlayer from "react-player";
import Slider from "./Slider";
import "../css/Gallery.css";

const Gallery = () => {
    const { id } = useParams();

    return (
        <section className="primary gallery">
            {(id === "exterior") && <Slider gallery={appConst.gallery.exterior}  />}
            {(id === "interior") && <Slider gallery={appConst.gallery.interior}  />}
            {(id === "video") && <ReactPlayer url='https://www.youtube.com/watch?v=3IQFCa3uT3I' playing={true} loop={true} controls={true} width='100%' height='100%' />}
            {(id === "360") && <iframe src="http://wizio.co.uk/projects/studioforma/villa-greece/360/" /> }
            {(id === "360-plus") && <iframe src="https://biganto.com/tour/21031/#21_0_0_-100000_287" /> }
        </section>
    );



};

export default Gallery;