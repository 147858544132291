export const buildingConst = {
    "type_a": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1446.6,658.7 1135.4,649.3 1057.6,652.6 1033.7,650.4 931.8,652.6 931.8,827.1 969.8,834.3 \n" +
                    "\t1058.7,836.2 1131.5,853.8 1446.6,812.6"},
            {status : 1, level: 1,points:"861.4,852.8 861.4,642.1 254.1,625.2 254.1,882.9"},
            {status : 1, level: 2,points:"932.3,411.1 973.4,400.5 994.6,405.8 994.6,414.8 1014,410.2 1295.3,442.3 1295.3,452.3 1367.8,462.3 1367.8,655.9 1135.4,649.3 1057.6,652.6 1033.7,650.4 976.5,651.5 931.8,652.6"},
            {status : 1, level: 3,points:"253.8,331 254.1,625.2 861.4,642.1 861.4,577.7 820.2,575.4 816.4,378.9 297.6,321.4 275.1,332.7"},

        ]
    },
    "type_b": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"850.6,592.3 904,589 1274,599.8 1274,724.8 907.3,750.7 850.6,745.7"},
            {status : 1, level: 1,points:"850.6,570.6 850.6,760.7 319.7,796.5 91.3,774.9 91.3,572.2 315.5,540.5"},
            {status : 1, level: 2,points:"850.6,374.8 897.3,368.1 897.3,381.4 935.6,381.4 1191.5,434.8 1194.8,597.5 904,589 850.6,592.3"},
            {status : 1, level: 3,points:"91.3,353.1 316.3,260.6 772.3,348.1 772.3,566.2 315.5,540.5 91.3,572.2"},
        ]
    },
    "type_c": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1238.8,667.1 1261,664.3 1358.2,664.3 1464,672.1 1465.5,840.4 1264.3,846.5 1238.8,843.2"},
            {status : 1, level: 1,points:"182.5,624 520.1,555.2 876.5,589.6 876.5,897.8 520.1,922.8 182.5,866.6"},
            {status : 1, level: 2,points:"1238.8,667.1 1238.8,411.4 1272.8,403.9 1387.8,446.4 1387.8,566.5 1402.8,567.7 1460.3,581.5 \n" +
                    "\t1464,672.1 1261,664.3"},
            {status : 1, level: 3,points:"415.8,116.9 796.4,245.9 796.4,451 837.7,458.5 837.7,585.4 520.1,554.7 182.5,623.5 182.5,293.4 \n" +
                    "\t415.8,116.9"},

        ]
    },

    "type_d": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"1286.2,650.7 326,650.7 326,917.4 1286.2,830.8"},
            {status : 1, level: 1,points:"1286.2,385.7 326,285.6 326,650.7 1286.2,650.7"},


        ]
    },

    "type_e": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"742.4,960.8 1209.2,879.1 1209.2,686.6 742.4,637.6"},
            {status : 1, level: 1,points:"1209.2,686.6 1209.2,459 742.4,335.6 742.4,637.6"},
           

        ]
    },

    "type_f": {
        viewBox: "0 0 1920 1080",
        floorPath: [
            {status : 1, level: 0,points:"212.3,330.6 212.3,430.7 178.9,444 178.9,769.1 1197.5,714.1 1197.5,435.7 1225.9,430.7 995.8,385.7 \n" +
                    "\t846.4,407 843.1,296.4 793.1,291.4 773.9,294.7 770.6,388.1 695.6,386.4 693.9,338.9 590.7,302.3 285.6,277.3"},


        ]
    },




}