import React, {useEffect, useState} from 'react';
import {appUtil} from "../constant/appUtil";
import LazyLoad from "react-lazyload";
import {Link} from "react-router-dom";
import {propertyConst} from "../constant/propertyConst";
import {appConst, appSettings} from "../constant/appConst";

function GridItem({property}) {
  const [statusClass,setStatusClass] = useState();
  useEffect(()=> {
    if(property.status_id === appConst.status.available) setStatusClass("available")
    if(property.status_id === appConst.status.sold) setStatusClass("sold")
    if(property.status_id === appConst.status.reserved) setStatusClass("reserved")
    if(property.status_id === appConst.status.not_released) setStatusClass("not_released")
  },[])
  function getImage(){
    let thumbs = propertyConst.thumb[appUtil.convertToSlug(property.room_type.label)];

    return (thumbs[0].source);
  }
  return (
  <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 item">
    <Link to={"/property-view/"+property.id}>
    <div className="inner">

      <LazyLoad><img className="img-fluid" src={getImage()} alt=""/></LazyLoad>
      
      <div className="details">
        <h3>{property.code} {statusClass && <span className={"property-status-icon " + statusClass}/>}</h3>

        <table>
          <tbody>

            <tr>
              <td>FLOOR TYPE</td>
              <td>{property.room_type.label}</td>
            </tr>
            <tr>
              <td>UNIT AREA</td>
              <td>{property.net_area} {appSettings.areaUnit}</td>
            </tr>
            <tr>
              <td>GARDEN AREA</td>
              <td>{property.garden_area} {appSettings.areaUnit}</td>
            </tr>
            <tr>
              <td>TERRACE AREA</td>
              <td>{property.terrace_area} {appSettings.areaUnit}</td>
            </tr>
            <tr>
              <td>BATHROOMS</td>
              <td>{property.bathrooms} </td>
            </tr>
            <tr>
              <td>PARKING</td>
              <td>{property.parking} </td>
            </tr>
          </tbody>
        </table>
      </div>
      
    </div>
    </Link>
  </div>

  );
}

export default GridItem;